import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState } from 'react'; // Import useState

export default function GalleryMultiStatic() {
  const animationVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const AnimatedImage = ({ src, delay }: { src: string; delay: number }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
    const [loaded, setLoaded] = useState(false); // State to track image loading

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={animationVariants}
        transition={{ duration: 0.5, delay }}
        className="w-full h-full rounded-lg overflow-hidden"
      >
        {!loaded && (
          // Skeleton placeholder
          <div className="animate-pulse bg-gray-300 w-full h-full"></div>
        )}
        <img
          alt="gallery"
          className={`w-full object-cover h-full object-center block rounded-lg ${
            loaded ? 'block' : 'hidden'
          }`}
          src={src}
          onLoad={() => setLoaded(true)} // Set loaded to true when the image has loaded
        />
      </motion.div>
    );
  };

  return (
    <section className="">
      <div className="container px-5 py-12 mx-auto flex flex-wrap xl:w-[75%] lg:w-[85%] sm:w-[80%]">
        <div className="flex flex-wrap md:-m-2 -m-1">
          <div className="flex flex-wrap w-1/2">
            {/* <div className="md:p-2 p-1 w-1/2">
              <AnimatedImage src={'/imgs/4.jpg'} delay={0.2} />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <AnimatedImage src={'/imgs/2.jpg'} delay={0.4} />
            </div> */}
            <div className="md:p-2 p-1 w-full">
              <AnimatedImage
                src={`/imgs/work_promo_${
                  Math.floor(Math.random() * 10) + 1
                }.gif`}
                delay={0.6}
              />
            </div>

            <div className="px-1 w-full">
              <div className="p-2 bg-gray-300/70 rounded-lg">
                {/* basic license info */}
                <h2 className="font-semibold">License Info</h2>
                <div className="pl-2">
                  <div className="flex flex-col text-left">
                    <p className="font-semibold">
                      Asbestos{' '}
                      <span className="font-normal">
                        <br />
                        #02019
                      </span>
                    </p>
                    <p className="font-semibold">
                      Lead{' '}
                      <span className="font-normal">
                        <br />
                        #00803A, #00806E
                      </span>
                    </p>
                    <p className="font-semibold">
                      LIC{' '}
                      <span className="font-normal">
                        <br />
                        #13VH12917600
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap w-1/2 ">
            <div className="md:p-2 p-1 w-full">
              <AnimatedImage src={'/imgs/abated-lic.jpg'} delay={0.6} />
            </div>
            <div className="md:p-2 p-1 w-full">
              <AnimatedImage src={'/imgs/Abated_Trucks.jpg'} delay={0.2} />
            </div>
            {/* <div className="md:p-2 p-1 w-1/2">
              <AnimatedImage src={workImg3} delay={0.4} />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
