import React from 'react';
import { motion } from 'framer-motion';

// Define the props for the component
interface ServiceCardProps {
  title: string;
  imageUrl: string;
  description: string;
}

// Framer Motion animation variants
const variants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 10,
    },
  },
};

// The ServiceCard functional component using Tailwind CSS and Framer Motion
const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  imageUrl,
  description,
}) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0 }} // Starts the animation when 10% of the element is in view
      variants={variants}
      className="bg-white shadow-md rounded-lg p-5 text-center max-w-[20rem]"
    >
      <h1 className="text-lg font-bold mb-3">{title}</h1>
      <img
        className="w-full mb-3 aspect-square rounded-lg"
        src={imageUrl}
        alt={title}
      />
      <p className="text-slate-700 text-sm text-start font-semibold">
        {description}
      </p>
    </motion.div>
  );
};

export default ServiceCard;
