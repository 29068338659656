export default function Footer() {
  return (
    <footer className="m-4 py-2 px-10 opacity-70 sm:p-6 text-slate-700 font-semibold">
      {/* upper row */}
      <div className="md:flex md:justify-between">
        {/* company name */}
        <div className="mb-6 md:mb-0"></div>

        {/* contact info */}
        <div className="grid grid-cols-2 gap-2 sm:gap-6 sm:grid-cols-4">
          {/* useful links */}
          <div>
            <h3 className="mb-6 text-sm font-semibold uppercase">
              Useful Links
            </h3>
            <ul>
              <li className="mb-4">
                <a
                  href="https://www.nj.gov/health/ceohs/asbestos/asbestos-faq/"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  Asbestos{' '}
                  <span>
                    <a href="https://www.asbestos.com/asbestos/#:~:text=Asbestos%20is%20a%20group%20of%20six%20naturally%20occurring%20minerals%20made%20up%20of%20heat%2Dresistant%20fibers">
                      NJ
                    </a>
                  </span>
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.nj.gov/health/ceohs/lead/"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  Lead{' '}
                  <span>
                    <a href="https://www.epa.gov/lead/learn-about-lead">NJ</a>
                  </span>
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.epa.gov/asbestos/learn-about-asbestos"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  EPA Asbestos
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.epa.gov/lead/lead-abatement-inspection-and-risk-assessment"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  EPA Lead
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.osha.gov/asbestos"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  OSHA
                </a>
              </li>
            </ul>
          </div>

          {/* useful links 2 */}
          <div>
            {/* <h3 className="mb-6 text-sm font-semibold uppercase">
              Useful Links
            </h3> */}
            <ul>
              <li className="mb-4">
                <a
                  href="https://topics.nj.com/tag/phil-murphy"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  NJ Politics
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://web.archive.org/web/20230326184129/https://www.cdc.gov/nceh/lead/prevention/health-effects.htm"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  CDC Lead Effects
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.epa.gov/lead/renovation-repair-and-painting-program"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  EPA Renovation
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.njleg.state.nj.us/2020/Bills/A9999/5343_R2.HTM"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  NJ Legislation A5343
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.njleg.state.nj.us/2020/Bills/A9999/5407_R1.HTM"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  NJ Legislation A5407
                </a>
              </li>
              <li>
                <a
                  href="https://www.njleg.state.nj.us/2020/Bills/S1500/1147_U1.PDF"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  NJ Senate Bill S1147
                </a>
              </li>
            </ul>
          </div>

          {/* follow us */}
          <div>
            <h3 className="mb-6 text-sm font-semibold uppercase">Follow us</h3>
            <ul>
              <li className="mb-4">
                <a
                  href="https://www.instagram.com/abated_acm/?hl=en"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.google.com/search?q=Abated+LLC&sxsrf=AJOqlzVac5PMezRm_nh9Ghn5-1oKhFF78A%3A1677898854857&ei=ZrQCZKr3M8Ku5NoPs4O1mA8&ved=0ahUKEwiqwtmppMH9AhVCF1kFHbNBDfMQ4dUDCBA&uact=5&oq=Abated+LLC&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzIECCMQJzoECAAQQzoGCAAQChBDOhAILhCxAxCDARDHARDRAxBDOgsILhDHARCvARCRAjoFCAAQkQI6CwguEMcBENEDEIAEOgsIABCxAxCDARCRAjoKCC4QxwEQrwEQQzoHCAAQsQMQQzoICAAQgAQQsQM6CgguELEDENQCEEM6CggAELEDELEDEEM6BQgAEIAEOgYIABAWEB5KBAhBGABQAFi8DWDvDmgAcAB4AIABrAGIAZ4IkgEDMy43mAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x89c24d9cd05993e9:0xc591c4a64c4765b,1,,,,"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  Google
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.homeadvisor.com/rated.AbatedLLC.101204426.html"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  Home Advisor
                </a>
              </li>
            </ul>
          </div>

          {/* legal */}
          <div>
            <h3 className="mb-6 text-sm font-semibold uppercase">Legal</h3>
            <ul>
              <li className="mb-4">
                <a className="hover:underline" href="/privacypolicy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className="hover:underline" href="/termsandconditions">
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>

          {/* contact */}
          <div className="relative bg-red-100 p-4 rounded-lg z-0 col-span-2 sm:col-span-3 lg:col-span-1">
            <div className="absolute left-0 top-0 bg-gray-300 rounded-lg opacity-25 w-full h-full z-10"></div>
            <h3 className="mb-6 text-sm font-semibold uppercase z-20">
              Contact
            </h3>
            <ul>
              <li className="mb-4">
                <p>
                  <span className="font-semibold">Contact:</span>{' '}
                  <span className="font-semibold text-lg">(908) 372-8375</span>
                </p>
              </li>
              <li>
                <p>
                  <span className="font-semibold">Location:</span>{' '}
                  <span className="font-semibold text-lg">
                    30 Union St, Elizabeth, NJ 07202
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* divider */}
      <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />

      {/* bottom row */}
      <div className="sm:flex sm:items-center sm:justify-between">
        {/* 20xx all rights reserved */}
        <span className="text-sm sm:text-center">
          © 2023 Abated. All Rights Reserved.
        </span>

        {/* social media icons */}
        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <a
            href="https://www.instagram.com/abated_acm/"
            className="hover:"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
}
