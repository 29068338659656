import React from 'react';
import { motion } from 'framer-motion';
import ServiceCard from './ServiceCard';

export default function LocationServices() {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  };

  return (
    <motion.section
      className="flex flex-col justify-center items-center w-full px-4 gap-12 bg-gray-100 py-16 rounded-lg"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="flex justify-center items-center">
        <h1 className="font-bold text-center text-4xl md:text-[3rem] xl:text-[5rem] mb-12">
          Services
        </h1>
      </div>
      <div className="flex flex-col gap-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* ServiceCard components */}
          <ServiceCard
            description=""
            title="Lead Abatement"
            imageUrl="/imgs/lead-paint.jpg"
          />
          <ServiceCard
            description=""
            title="Asbestos Abatement"
            imageUrl="imgs/general-contracting.jpg  "
          />
          <ServiceCard
            description=""
            title="Lead Inspections"
            imageUrl="imgs/lead-test.jpg"
          />
          {/* ServiceCard components */}
          <ServiceCard
            description=""
            title="Asbestos Inspections"
            imageUrl="/imgs/asbestos-lab.jpg"
          />
          <ServiceCard
            description=""
            title="Demolition Services"
            imageUrl="/imgs/demolition-2.jpg"
          />
          <ServiceCard
            description=""
            title="General Contracting Services"
            imageUrl="imgs/general-contracting-2.jpg"
          />
        </div>
        {/* <ServiceLocations /> */}
      </div>
    </motion.section>
  );
}
