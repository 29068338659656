import { motion } from 'framer-motion';
import React, { useState } from 'react';
import JobApplicationForm from './JobApplicationForm';

export default function ContentImage() {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isClickedHide, setIsClickedHide] = useState<boolean>(true);

  const handleButtonClick = () => {
    setIsClicked(true);
  };

  const toggleButtonClickHide = () => {
    setIsClickedHide(!isClickedHide);
  };

  const divVariants = {
    initial: {
      opacity: 1,
      x: 0,
      y: 0,
    },
    scattered: (index: number) => ({
      opacity: 0,
      x: Math.random() * 1000 - 500,
      y: Math.random() * 1000 - 500,
      transition: {
        duration: 1,
        delay: index * 0.1,
      },
    }),
  };

  return (
    <div>
      <div className={`${isClickedHide ? 'hidden' : ''}`}>
        <JobApplicationForm />
      </div>
      <section className="static z-10">
        <div className="flex justify-center items-center mx-auto lg:h-[80vh] max-w-screen-2xl px-4 py-8 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <motion.div
              className="bg-white p-8 lg:px-16 lg:py-24"
              key={3}
              variants={divVariants}
              initial="initial"
              animate={isClicked ? 'scattered' : 'initial'}
              custom={3}
            >
              <div className="mx-auto max-w-xl flex flex-col justify-center items-center">
                <h2 className="text-2xl font-bold text-black md:text-3xl text-center">
                  Looking for a career in the construction industry?
                </h2>

                <p className="text-black/90 sm:mt-4 sm:block">
                  We are always looking for new talent to join our team. If you
                  are a hard worker and have a passion for the construction
                  industry, we would love to hear from you. We offer competitive
                  wages and benefits. Apply today!
                </p>

                <div className="mt-4 md:mt-8">
                  <button
                    onClick={() => {
                      handleButtonClick();
                      //   wait 1 second and then hide the button
                      setTimeout(() => {
                        toggleButtonClickHide();
                      }, 1500);
                    }}
                    className="block max-w-sm rounded bg-primary px-12 py-3 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-primary"
                  >
                    Apply Now!
                  </button>
                </div>
              </div>
            </motion.div>

            <div className="grid grid-cols-2 gap-4 md:grid-cols-1 lg:grid-cols-2">
              <motion.img
                alt="Student"
                src={'/imgs/1.jpg'}
                className="h-40 w-full object-cover sm:h-56 md:h-full"
                key={1}
                variants={divVariants}
                initial="initial"
                animate={isClicked ? 'scattered' : 'initial'}
                custom={1}
              />

              <motion.img
                alt="Student"
                src={'/imgs/2.jpg'}
                className="h-40 w-full object-cover sm:h-56 md:h-full"
                key={2}
                variants={divVariants}
                initial="initial"
                animate={isClicked ? 'scattered' : 'initial'}
                custom={2}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
