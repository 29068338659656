import React from 'react';
import { motion } from 'framer-motion';

export default function ContactCard() {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  };
  return (
    <div className="flex justify-center items-center bg-gray-100">
      <motion.div
        className="bg-white p-3 rounded-lg shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)] w-full max-w-md py-6"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* <div className="mb-6 w-[50%]">
          <img
            src="/imgs/RP.jpg"
            alt="Contact Us"
            className="w-full aspect-square object-cover rounded-full"
          />
        </div> */}
        <div className="flex flex-row gap-4">
          <div>
            <div className="mb-3 w-[50%]">
              <img
                src="/imgs/RP.jpg"
                alt="Contact Us"
                className="w-full aspect-square object-cover rounded-full"
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Ryan Passos</h3>
              <p className="text-gray-600">Project Manager</p>
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-6">
              Have a question or need assistance? Feel free to reach out to us
              using the contact information below.
            </p>
          </div>
        </div>
        <div className="mb-4">
          {/* <p className="text-gray-600">
            <span className="font-semibold">Phone:</span> (908) 372-8375
          </p> */}
          {/* button */}
          <a
            href="tel:9083728375"
            className="block w-full py-2 text-center text-white bg-primary rounded-lg mt-4"
          >
            Call Now
          </a>
          {/* go to service.tsx with  link */}
          <a
            href="/services"
            className="block w-full py-2 text-center text-white bg-primary rounded-lg mt-4"
          >
            Email Now
          </a>
        </div>
        <div>
          {/* <p className="text-gray-600">
            <span className="font-semibold">Location:</span> 111 Clark Place,
            Elizabeth, NJ 07206
          </p> */}
        </div>
      </motion.div>
    </div>
  );
}
